<script setup lang="ts">
import { ref, onMounted } from "vue"
import { getTranslation, setLogo, getSettings } from "@/ts/utilities.ts"
import MegaNav from "@/components/MegaNav.vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import RenderAction from "@/components/helpers/RenderAction.vue";

const props = defineProps({
	toggleNavigation: {
		type: Boolean,
		default: false
	}
})

const emit = defineEmits(['closeMobileMenu'])

const settings = getSettings()

const visible = ref(false)
const showMegaNav = ref(false)
const megaNav = ref({})
const mobileNav = ref({})

const mobileNavParent = ref({})

const navigation = ref({})

const getNavigation = async () => {
	await fetch('/mobler-api/v2/ecom/settings/navigation')
		.then(response => response.json())
		.then(data => {
			navigation.value = data.rootNode.children
		})
}

const toggleMegaNav = (navItem) => {
	if (megaNav.value.id !== navItem.id) {
		megaNav.value = navItem
		showMegaNav.value = true
	} else {
		megaNav.value = {}
		showMegaNav.value = false
	}
}

const toggleMobileNav = (navItem) => {
	// Set the clicked nav item as the parent
	mobileNavParent.value = navItem


	if (navItem.children) {
		mobileNav.value = navItem
	} else {
		mobileNav.value = {}
	}
}


const closeMegaNav = () => {
	megaNav.value = {}
	showMegaNav.value = false
}

const closeMobileMenu = () => {
	visible.value = false
	emit('closeMobileMenu')
}

onMounted (() => {
	if (props.toggleNavigation) {
		visible.value = true
	}

	// Get top level navigation
	getNavigation()
})
</script>

<template>
	<div>
		<div class="max-md:absolute max-md:top-0 max-md:left-0 max-md:h-dvh max-md:w-full max-md:bg-white max-md:z-10"
			:class="{ 'max-md:hidden': !props.toggleNavigation }">

			<div class="flex md:hidden justify-between p-4 border-b border-b-grey">
				<img class="max-h-[30px]" :src="setLogo()" :alt="getTranslation('LogoAltText')" />
				<button
					type="button"
					class="flex items-center justify-self-end ml-auto px-3 text-base hover:underline"
					@click="closeMobileMenu"
				>
					<span class="mr-2 inline-flex">
						{{ getTranslation('Close') }}
					</span>
					<font-awesome-icon :icon="['fal', 'close']" />
				</button>
			</div>

			<nav>
				<ul class="flex items-center flex-wrap gap-y-6 max-md:py-4 lg:flex-row lg:gap-6 list-none">
					<li v-for="navItem in navigation" :key="navItem.id" class="font-semibold text-sm md:text-xl md:font-semibold max-md:w-1/2 last-of-type:ml-auto">
						<RenderAction
							v-if="navItem.hasChildren"
							type="button"
							theme="none"
							size="none"
							class="hidden md:flex hover:underline"
							@click="toggleMegaNav(navItem)"
							:class="{ 'underline': megaNav == navItem }">
							{{ navItem.name }}
						</RenderAction>

						<RenderAction
							v-else-if="navItem.menuCustomStyling"
							theme="none"
							class="rounded-full"
							:style="{ 'color': navItem.menuTextColor, 'background-color': navItem.menuBackgroundColor }"
						>
							{{ navItem.name }}
						</RenderAction>

						<RenderAction
							v-else
							type="link"
							theme="none"
							size="none"
							class="hidden md:flex hover:underline"
							:data="navItem.link">
							{{ navItem.name }}
						</RenderAction>

						<a
							class="md:hidden px-4 flex flex-col gap-2"
							:href="navItem.url"
							@click.prevent="toggleMobileNav(navItem)">

							<img class="object-contain w-full aspect-[4/3]"
								v-if="navItem.image"
								 :src="'/Admin/Public/GetImage.ashx?Image=' + navItem.image + '&Width=175&height=100&Format=webP&Quality=90&Crop=5&resolution=100'"
								 :alt="navItem.title"
								 loading="lazy"
							>
							{{ navItem.title }}
						</a>
					</li>
				</ul>

				<!-- Back button, which should be displayed when a child nav is shown.
				 Clicking it renders the parents children again -->
				<button
					v-if="mobileNav.children"
					class="flex items-center gap-2 p-4 border-t border-t-grey"
					@click="toggleMobileNav(mobileNavParent)">
					<font-awesome-icon :icon="['fal', 'arrow-left']" />
					{{ getTranslation('Back') }}
				</button>

				<ul v-if="mobileNav.children" class="list-none">
					<li v-for="navItem in mobileNav.children" :key="navItem" class="font-semibold text-sm md:text-xl">
						<a
							class="md:hidden px-4 flex flex-col gap-2"
							:href="navItem.url"
							@click.prevent="toggleMobileNav(navItem)">
							<img v-if="navItem.image"
								 :src="'/Admin/Public/GetImage.ashx?Image=' + navItem.image + '&Width=175&height=100&Format=webP&Quality=90&Crop=5&resolution=100'"
								 :alt="navItem.title">
							{{ navItem.title }}
						</a>
					</li>

				</ul>
			</nav>

			<MegaNav
				v-if="showMegaNav && megaNav"
				:navItems="megaNav.children"
				@close="closeMegaNav"
			></MegaNav>
		</div>
	</div>
</template>
