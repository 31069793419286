﻿import {inject} from 'vue'
import {MarkerData} from '@/interfaces/MapsViewInterface.ts'

// Get translations from DW through their API
const getTranslation = ( key: string, defaultValue: string = '' ) => {
	const translations = inject('translations')
	const translationEntry = translations.find(translation => translation.Key === key)
	if (translationEntry) {
		return translationEntry.Value ? translationEntry.Value : translationEntry.DefaultValue
	}
	console.warn(`Missing translation - "${key}"`)
	return key
}

// Get website settings
const getSettings = () => {
	return inject('settings')
}

const settings = getSettings()

// Scroll to an element
// Link to the anchor with a ref, e.g. @click="goto($refs.productSpecifications)"
const goTo = (element: string) => {
	const el = element
	if (el) {
		el.scrollIntoView({ behavior: 'smooth' })
	}
}

// Read more functionality
const readMore = (element: string) => {
	const readMoreContainer = element.querySelector('.read-more-container')
	readMoreContainer.style.maxHeight = '9999px'
	readMoreContainer.style.transition = 'max-height 400ms ease-in-out'
	element.querySelector('.read-more-fade').style.display = 'none'
}

// Get unique guid
const getGuid = () => {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
	}
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

const setShop = (dealer: MarkerData) => {
	fetch(`/mobler-api/v2/shops/setshop?ShopName=${dealer.MenuText}&IsAboutUsPage=false`).then(resp => resp.json()).then((json) =>{
		if(json.redirectLink){
			location.href = json.redirectLink
		} else{
			location.href = dealer.Link
		}
	})
}

const setLogo = () => {
	let shopname = settings?.shopname?.toLowerCase()

	if (shopname && shopname != "møblér" && shopname != "mobler" && shopname != "m%c3%b8bl%c3%a9r") {
		return `/Files/Templates/Designs/Mobler/dist/logos/mobler-${shopname}-mobile.svg`
	} else {
		return "/Files/Templates/Designs/Mobler/dist/logos/mobler.svg"
	}
}

export { getTranslation, getSettings, goTo, getGuid, readMore, setShop, setLogo }
