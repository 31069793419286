<script setup lang="ts">
import { ref, onBeforeUnmount, onMounted } from "vue"
import { getTranslation } from "@/ts/utilities.ts";
import RenderAction from '@/components/helpers/RenderAction.vue'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps({
  	navItems: Array
})

const showChildNav = ref(false)

const emit = defineEmits(['close'])

function keydownHandler(event: { key: string; }) {
	if (event.key === 'Escape') {
		close()
	}
}
function close() {
	emit('close')
}

const openChildNav = (navItem) => {
	showChildNav.value = navItem.children
	selectedNavItem.value = navItem
}

const selectedNavItem = ref(null)

const filterGroupNavigations = (navItem) => {
	return navItem.filter((navItem) => navItem.nodeType !== 'GROUPSHOWALL')
}


onMounted(() => {
	// Listen for keydown events
	window.addEventListener('keydown', keydownHandler)
})

onBeforeUnmount(() => {
	// Remove keydown event listener
	window.removeEventListener('keydown', keydownHandler)
})

</script>

<template>
	<div class="absolute left-0 right-0 top-0 md:top-full min-h-[50vh] bg-white drop-shadow border-t border-t-grey z-20">
		<div class="container pt-8">
			<button
				type="button"
				class="hidden lg:flex items-center justify-self-end ml-auto px-3 text-base hover:underline"
				@click="close"
			>
				<span class="mr-2 hidden lg:inline-flex">
					{{ getTranslation('Close') }}
				</span>
				<font-awesome-icon :icon="['fal', 'close']" />
			</button>
			<div class="lg:grid lg:grid-cols-12 pt-8 pb-16 overflow-y-scroll">
				<nav class="lg:col-span-6">
					<ul class="w-1/2 list-none flex flex-col relative gap-y-4 pr-12">
						<li v-for="navItem in filterGroupNavigations(navItems)"
							:key="navItem.id"
							class="flex">

							<RenderAction
								action="button"
								theme="none"
								size="none"
								class="group inline-flex items-center justify-between w-full hover:underline"
								:class="{ 'font-bold': showChildNav === navItem.children }"
								@click="openChildNav(navItem)"
								>
								{{ navItem.name }}
								<font-awesome-icon class="transition-transform group-hover:translate-x-1" :icon="showChildNav === navItem.children ? ['fas', 'arrow-right'] : ['fal', 'arrow-right']" />
							</RenderAction>

							<div class="absolute left-full top-0 w-full pb-8"
								:class="{ 'hidden': showChildNav !== navItem.children }">
								<ul v-if="navItem.children" class="list-none flex flex-col gap-y-4 border-l border-l-grey pl-12 pr-8">
									<li v-for="child in navItem.children" :key="child" class="*:no-underline *:hover:underline">

										<RenderAction
											v-if="child.nodeType == 'GROUPSHOWALL'"
											action="link"
											theme="link"
											size="none"
											:data="child.link"
											>
											{{ child.name }}
										</RenderAction>

										<RenderAction
											v-else
											action="link"
											theme="link"
											size="none"
											class="inline-flex items-center justify-between w-full"
											:data="child.link"
											>
											{{ child.name }}
										</RenderAction>

									</li>
								</ul>
							</div>
						</li>
					</ul>
				</nav>
				<div class="lg:col-span-6">
					<img v-if="selectedNavItem && selectedNavItem.image1"
						 width="736"
						 height="414"
						 class="object-fit object-cover aspect-[16/9] rounded"
						:src="'/Admin/Public/GetImage.ashx?Image=' + selectedNavItem.image1 + '&Width=736&height=414&Format=webP&Quality=90&Crop=5&resolution=100'"
						:alt="selectedNavItem.name"
						 loading="lazy"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
