export default class Cylindo {
	public static viewers: object[] = []
	private static imageDetailsGrid: object[] = []
	public static cylindoDetailsMap: object[] = [
		{
			width: 1200,
			height: 1200,
			crop: [1745, 1364, 520, 520],
			custom: false,
			type: 'image',
			frame: 28
		},
		{
			width: 1200,
			height: 1200,
			crop: [2615, 567, 1080, 1080],
			custom: false,
			type: 'image',
			frame: 28
		}
	]

	public static selectedCylindoFeatures = {}

	private static isDebug() {
		return location.host.indexOf('localhost') > -1 || location.host.indexOf('staging') > -1 || location.search.includes('codebug=true')
	}

	private static getCylindoAreaSettings() {
		const domCylindoSettings = document.querySelector('.js-cylindo-area-settings')
		let cylindoSettings = {}
		if (domCylindoSettings !== null) {
			cylindoSettings = domCylindoSettings.getAttributeNames().reduce((obj, name) => {
				if (name.startsWith('data-')) {
					if (name.endsWith('translations')) {
						return {
							...obj,
							[name.slice(name.indexOf('-') + 1)]: JSON.parse(domCylindoSettings.getAttribute(name))
						}
					}
					return {...obj, [name.slice(name.indexOf('-') + 1)]: domCylindoSettings.getAttribute(name)}
				}
				return obj
			}, {})
		}
		return cylindoSettings
	}

	private static accountId(): number {
		const cylindoSettings = Cylindo.getCylindoAreaSettings()
		return cylindoSettings['accountid']
	}

	private static getTranslations(opts): object {
		const cylindoSettings = Cylindo.getCylindoAreaSettings()
		const translations = cylindoSettings['translations']
		Object.keys(translations).forEach((translationKey) => {
			opts[translationKey] = translations[translationKey]
		})
		return opts
	}

	public static renderCylindoElement = (data, subComponentTagName, options) => {
		let subComponentDomElement = null
		const mainComponentOptions = ['id', 'controls']

		if (subComponentTagName !== null) {
			subComponentDomElement = document.createElement(subComponentTagName)
			Object.keys(options).forEach((optionKey) => {
				if (mainComponentOptions.includes(optionKey) === false) {
					subComponentDomElement.setAttribute(optionKey, options[optionKey])
				}
			})
		}
		return `<cylindo-viewer
              id="${options.id ?? ''}"
              customer-id="${data.accountId}"
              code="${data.id}"
              controls="${options.controls ?? ''}">
              ${subComponentDomElement ? subComponentDomElement.outerHTML : ''}
          </cylindo-viewer>`

	}

	private static convertCylindoFeatureStringToObject(inputString) {
		const resultObject = {}

		for (let i = 0; i < inputString.length; i += 2) {
			const key = inputString[i]
			const value = inputString[i + 1]
			resultObject[key] = value
		}

		return resultObject
	}

	private static convertCylindoFeatureArrayToObject(inputArray) {
		const resultObject = inputArray.reduce((acc, item) => {
			const [key, ...value] = item.split(' ');
			acc[key] = value.join(' ');
			return acc;
		}, {});

		return resultObject
	}

	public static addViewer(opts: object, instanceName: string) {
		const component = document.querySelector(`cylindo-viewer[id="${instanceName}"]`)
		if (component !== null) {
			opts = Cylindo.getTranslations(opts)
			opts['accountID'] = Cylindo.accountId()
			opts['productCode'] = component.getAttribute('code')
			Cylindo.viewers[instanceName] = {
				component: component,
				options: opts
			}
			Cylindo.selectedCylindoFeatures = opts['features']
			Cylindo.setFeatures()

			Cylindo.setInstanceConfiguration(instanceName, opts)
		}
	}

	public static setFeatures(target = 'all', localFeatures = null) {
		if(target === 'modal'){
			const cylindoDomModalElement = document.querySelector('#cylindo-modal-container')
			cylindoDomModalElement.features = localFeatures
		} else {
			const cylindoDomElements = document.querySelectorAll('cylindo-viewer')
			const cylindoImages = document.querySelectorAll('.js-cylindo-carousel-image')
			cylindoDomElements.forEach(cy => cy.features = Cylindo.selectedCylindoFeatures)

			cylindoImages.forEach((cylindoImage) => {
				if (cylindoImage.src.includes('cylindo')) {
					let imageSrc = cylindoImage.src
					if (imageSrc.includes('feature')) {
						imageSrc = imageSrc.substring(0, imageSrc.indexOf('feature'))
					}
					let queryString = ''

					Object.keys(Cylindo.selectedCylindoFeatures).forEach((key) => {
						if (key !== undefined && Cylindo.selectedCylindoFeatures[key] !== undefined) {
							queryString += `feature=${key}:${Cylindo.selectedCylindoFeatures[key]}&`
						}
					})
					cylindoImage.src = `${imageSrc}${queryString}`
				}

			})
		}

		if (Cylindo.isDebug()) {
			console.trace('set features', Cylindo.selectedCylindoFeatures)
		}

	}

	public static getFeatures() {
		const cylindoDomElement = document.querySelectorAll('cylindo-viewer')[0]
		if (Cylindo.isDebug()) {
			console.log('get features', cylindoDomElement.features)
		}
		return cylindoDomElement.features
	}

	public static getDefaultFeatureCode(featureKey: string, instanceName: string) {
		let defaultFeatureCode = ''
		if (Cylindo.viewers[instanceName] !== undefined) {
			const feature = Cylindo.viewers[instanceName].configuration.features.find(f => f.code === featureKey)
			if (feature && feature.options) {
				let defaultFeature = feature.options.find(o => o.isDefault === true)
				if (defaultFeature === undefined) {
					defaultFeature = feature.options[0]
				}

				defaultFeatureCode = defaultFeature.code
			}
		}

		if (Cylindo.isDebug()) {
			console.log('get default feature code', featureKey, defaultFeatureCode)
		}

		return defaultFeatureCode
	}

	public static setInstanceConfiguration(instanceName, opts) {
		const productCode = Cylindo.viewers[instanceName].component.getAttribute('code')
		const url = `//content.cylindo.com/api/v2/${Cylindo.accountId()}/products/${productCode}/configuration`
		fetch(url)
			.then((response) => response.json())
			.then((json) => {
				Cylindo.viewers[instanceName].configuration = json
				Cylindo.viewers[instanceName].featureTemplates = json.features.map((feature) => {
					return feature.options.map((option) => {
						return `${feature.code}:${option.code}`
					})
				})
				Cylindo.viewers[instanceName].featureKeys = json.features.map((feature) => {
					return feature.code.toLowerCase()
				})
			})
	}

	public static getDefaultOverrideFeatureOptions(instanceName, cylindoFeatureCode) {
		const instanceConfig = Cylindo.viewers['cylindo-main-container'].configuration
		const feature = instanceConfig.features.find(f => f.code === cylindoFeatureCode)
		let returnValue = []
		if (feature !== undefined && feature.options.length > 0) {
			returnValue = feature.options
		}
		return returnValue
	}

	public static instanceConfigurationReady(instanceName) {
		return new Promise((resolve) => {
			const interval = setInterval(() => {
				if (Cylindo.viewers[instanceName] && Cylindo.viewers[instanceName].configuration) {
					clearInterval(interval)
					resolve(Cylindo.viewers[instanceName].configuration)
				}
			}, 100)
		})
	}

	public static getDetailsGrid(features: string[], productCode: string, gridData: string[]) {
		const returnValue: string[] = []
		Cylindo.imageDetailsGrid = []

		/*
		const instanceConfig = Cylindo.viewers['cylindo-main-container'].configuration
		const position = instanceConfig.features.find(f => f.code === 'POSITION')

		 */


		let queryString: string = ''
		if (features.length) {
			for (let i = 0; i < features.length; i += 2) {
				if (features[i] !== undefined && features[i + 1] !== undefined) {
					queryString += `feature=${features[i]}:${features[i + 1]}&`
				}
			}
		}
		/*
		if (position !== undefined) {
			const defaultPositionCode = Cylindo.getDefaultFeatureCode('POSITION', 'cylindo-main-container')
			const nextPosition = position.options.filter(o => o.code !== defaultPositionCode)
			if (nextPosition.length > 0) {
				queryString += `feature=POSITION:${nextPosition[0].code}`
				Cylindo.imageDetailsGrid.push({
					width: 1200,
					height: 1200,
					crop: false,
					custom: true,
					type: 'image',
					url: `https://content.cylindo.com/api/v2/${Cylindo.accountId()}/products/${productCode}/frames/1/${productCode}.webp?${queryString}&size=800`
				})
			}
		}

		 */

		Cylindo.cylindoDetailsMap.forEach((cylindoShoot) => {
			Cylindo.imageDetailsGrid.push(cylindoShoot)
		})

		/*

		gridData.forEach((contentObj: any) => {

			Cylindo.imageDetailsGrid.push({
				width: 1200,
				height: 1200,
				crop: false,
				custom: true,
				type: contentObj.type,
				url: contentObj.src
			})

		})

		 */

		Cylindo.imageDetailsGrid.forEach((opt: any) => {

			if (!opt.custom) {
				const croppedQuery: string = opt.crop ? `crop=(${opt.crop.join(',')})&` : `size=(${opt.width},${opt.height})&`
				opt.url = `https://content.cylindo.com/api/v2/${Cylindo.accountId()}/products/${productCode}/frames/${opt.frame}/${productCode}.webp?${croppedQuery}${queryString}`
			}
			returnValue.push(opt)
		})

		return returnValue
	}

	public static isReady() {
		return customElements.whenDefined('cylindo-viewer')
	}

	public static shouldLoad() {
		return document.querySelectorAll('cylindo-viewer').length > 0
	}

	constructor() {
	}
}
