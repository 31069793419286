<script setup lang="ts">
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import { ref, onMounted } from "vue"
import {getTranslation, setLogo} from "@/ts/utilities.ts"
import TopNavigation from "@/components/TopNavigation.vue"
import RenderAction from "@/components/helpers/RenderAction.vue"
import ActionBar from "@/components/ActionBar.vue";
import Navigation from "@/components/Navigation.vue";
import SearchBar from "@/components/SearchBar.vue";
import ShopWidget from "@/components/ShopWidget.vue";

const isCart = ref(false)
const isCheckout = ref(false)
const isCompletedOrder = ref(false)
const toggleNavigation = ref(false)

const back = () => {
	window.history.back()
}

onMounted(() => {
	const url = new URL(window.location.href)
	const urlParams = new URLSearchParams(window.location.search)

	if (url.pathname.includes('indkoebskurv')) {
		isCart.value = true
	}

	if (url.pathname.includes('checkout')) {
		isCheckout.value = true
	}


	if (isCheckout && urlParams.has('CompletedOrderId')) {
		isCompletedOrder.value = true
	}
})


</script>

<template>
	<TopNavigation />
	<header>
		<div class="container-fluid lg:border-b lg:border-b-grey">
			<div class="container">
				<div class="grid items-center gap-x-2 gap-y-4 xl:gap-8 pt-4 lg:py-4 lg:grid-rows-1 grid-cols-4 lg:grid-cols-3 auto-rows-max"
					:class="isCheckout ? 'grid-rows-1' : 'grid-rows-2'">

					<div v-if="isCheckout && !isCompletedOrder" class="w-fit col-span-2 lg:col-span-1">
						<RenderAction
							action="button"
							theme="white"
							@click="back()">
							<font-awesome-icon :icon="['fas', 'arrow-left']" />
							Tilbage
						</RenderAction>
					</div>

					<div class="flex lg:items-center lg:justify-center col-span-2 lg:col-span-1 lg:col-start-2"
						:class="isCheckout ? 'col-start-3' : ''">
						<div class="w-full">
							<a href="/" class="flex flex-col md:items-center">
								<img width="400" height="46" class="max-h-[50px] hidden md:inline-block" :src="setLogo()" :alt="getTranslation('LogoAltText')" />
								<!-- TODO: Mobile version missing -->
								<!-- <img width="400" height="46" class="max-h-[50px] w-full object-contain object-left inline md:hidden" src="@logoSrcMobile" alt='@Translate("LogoAltText", "Møblér: Alt indenfor møbler og boligindretning")'/>-->
								<!-- TODO: Logic missing -->
								<!-- <small class="text-xs text-grey-dark md:text-center">@Translate("LogoServiceText", "Ordren serviceres af Møbler.dk")</small>-->
							</a>
						</div>
					</div>

					<ActionBar v-if="!isCheckout" class="flex items-center col-span-2 lg:col-span-1" />

					<div v-if="!isCheckout" class="flex col-span-full gap-x-2 lg:col-span-1 lg:col-start-1 lg:row-start-1">
						<button class="flex flex-col justify-center items-center w-12 lg:hidden"
								@click="toggleNavigation = true">
							<font-awesome-icon :icon="['fal', 'bars']" class="text-3xl" />
							<span class="mt-1 text-xs md:text-sm">{{ getTranslation('MobileMenu.Text') }}</span>
						</button>

						<SearchBar id="js-search-bar"
								   class="w-full"
								   data-no-results="@SearchNoResults"/>
					</div>

					<div v-if="isCheckout" class="flex items-center justify-end col-span-2 lg:col-span-1">
						<!-- TODO: Check that it displays data -->
						<ShopWidget></ShopWidget>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!isCheckout" class="container py-4">
			<Navigation
				:toggleNavigation="toggleNavigation"
				@closeMobileMenu="toggleNavigation = false"
			/>
		</div>
	</header>
</template>

<style scoped>

</style>
